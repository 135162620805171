//export const apiHost = "https://routefinder-dev.dreamztesting.com/API/"; // dev
//export const CAPTCHASITEKEY = "6LdIynEoAAAAAC89ZKLQ4x1Kobzi5xnZ4qX6MDWg"; // dev

//export const apiHost = "https://hanger-api.innovationforce.io/"; // prod
//export const CAPTCHASITEKEY = "6Lc1iOAoAAAAAFqzflxcbm9U2SkYd_TEuyanqlhf"; // prod


//export const apiHost = "https://hangardevapipge.azurewebsites.net/"; // new server dev
//export const CAPTCHASITEKEY = "6LdIynEoAAAAAC89ZKLQ4x1Kobzi5xnZ4qX6MDWg"; // dev

export const apiHost = process.env.REACT_APP_API_DOMAIN
export const CAPTCHASITEKEY = process.env.REACT_APP_CAPTCHASITEKEY
export const TINYMCEAPIKEY = process.env.REACT_APP_TINYMCEAPIKEY
export const LOGIN_VERIFICATION = process.env.REACT_APP_LOGIN_VERIFICATION == 'true'
export const LOGO_IMAGE = process.env.REACT_APP_LOGO

export const NOIMAGES = {
    profileImageLarge: '/assets/images/avatar.jpg',
    profileImageSmall: '/assets/images/avatar.jpg',
    pinImage: '/assets/images/no-pin-image.jpg',
}

