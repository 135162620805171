import './react-activity-feed/styles/index.css';
import './feed.css';

import {
  Activity,
  ActivityFooter,
  ActivityProps,
  CommentField,
  CommentItemProps,
  CommentList,
  DefaultAT,
  CommentItem as DefaultCommentItem,
  DefaultUT,
  FlatFeed,
  InfiniteScrollPaginator,
  LoveButton,
  StatusUpdateForm,
  StreamApp,
  Textarea,
  useFeedContext,
} from './react-activity-feed';
import { EnrichedActivity, Activity as GetStreamActivity, Reaction, UR } from 'getstream';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'
import { useCallback, useState } from 'react';

import { apiHost } from '../config';
import { getUserStore } from '../_common/helper/LocalStorage';
import { userOrDefault } from './react-activity-feed/utils';

const apiKey = 'fhfkrnzvkswr';
const appId = '1287777';

function CommentItem<
  UT extends DefaultUT = DefaultUT,
  AT extends DefaultAT = DefaultAT,
  CT extends UR = UR,
  RT extends UR = UR,
  CRT extends UR = UR,
  PT extends UR = UR
>({ comment, activity }: CommentItemProps<UT, RT, CRT> & {
  activity: EnrichedActivity<UT, AT, CT, RT, CRT>;
}) {
  const feed = useFeedContext<UT, AT, CT, RT, CRT, PT>();
  const actor = userOrDefault<UT>(comment.user);
  const user = getUserStore();
  const [editing, setEditing] = useState(false);

  if (!!comment?.children_counts?.['delete']) return null;

  return (
    <div>
      <div className="raf-wrapper">
        <DefaultCommentItem
          style={{ flexGrow: 1 }}
          comment={comment}
          HeaderRight={() => (
            <div>
              <div className="btn-group dropstart">
                <button type="button" className="btn" data-bs-toggle="dropdown" aria-expanded="false" style={{
                  borderRadius: '8px'
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="6" viewBox="0 0 26 6" fill="none">
                    <path d="M13.3998 6C15.0539 6 16.3998 4.65408 16.3998 3C16.3998 1.34592 15.0539 0 13.3998 0C11.7457 0 10.3998 1.34592 10.3998 3C10.3998 4.65408 11.7457 6 13.3998 6ZM13.3998 1.2C14.3923 1.2 15.1998 2.00748 15.1998 3C15.1998 3.99252 14.3923 4.8 13.3998 4.8C12.4073 4.8 11.5998 3.99252 11.5998 3C11.5998 2.00748 12.4073 1.2 13.3998 1.2ZM22.9998 0C21.3457 0 19.9998 1.34592 19.9998 3C19.9998 4.65408 21.3457 6 22.9998 6C24.6539 6 25.9998 4.65408 25.9998 3C25.9998 1.34592 24.6539 0 22.9998 0ZM22.9998 4.8C22.0073 4.8 21.1998 3.99252 21.1998 3C21.1998 2.00748 22.0073 1.2 22.9998 1.2C23.9923 1.2 24.7998 2.00748 24.7998 3C24.7998 3.99252 23.9923 4.8 22.9998 4.8ZM3.7998 6C5.45388 6 6.7998 4.65408 6.7998 3C6.7998 1.34592 5.45388 0 3.7998 0C2.14572 0 0.799805 1.34592 0.799805 3C0.799805 4.65408 2.14572 6 3.7998 6ZM3.7998 1.2C4.79232 1.2 5.5998 2.00748 5.5998 3C5.5998 3.99252 4.79232 4.8 3.7998 4.8C2.80728 4.8 1.9998 3.99252 1.9998 3C1.9998 2.00748 2.80728 1.2 3.7998 1.2Z" fill="black" />
                  </svg>
                </button>
                <ul
                  className='dropdown-menu'
                  style={{ minWidth: "110px", padding: "5px 0" }}
                >
                  {`${actor.id}` === `${user.userID}` ? (
                    <>
                      <li>
                        <button
                          className='btn btn-light text-start'
                          style={{ width: "100%", padding: "3px 15px", borderRadius: "unset" }}
                          onClick={() => {
                            feed.onToggleChildReaction('delete', comment as Reaction<RT>, {} as CRT, {});
                          }}
                        >
                          delete
                        </button>
                      </li>
                      <li>
                        <button
                          className='btn btn-light text-start'
                          style={{ width: "100%", padding: "3px 15px", borderRadius: "unset" }}
                          onClick={() => {
                            setEditing(true);
                          }}
                        >
                          edit
                        </button>
                      </li>
                    </>
                  ) : (
                    <li>
                      <button
                        className='btn btn-light text-start'
                        style={{
                          width: "100%", padding: "3px 15px", borderRadius: "unset", color:
                            !!comment?.own_children?.['report']?.length ? 'grey' : undefined
                        }}
                        onClick={() => {
                          feed.onToggleChildReaction('report', comment as Reaction<RT>, {} as CRT, {});
                        }}
                      >
                        report
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
          Content={editing ? (
            <CommentField
              style={{ width: '100%', padding: '0 16px 0 0' }}
              activity={activity}
              Textarea={({ value, ...props }) => <Textarea value={value || (typeof comment?.data?.text === 'string' && comment.data.text) || ''} {...props} />}
              doRequest={async (reaction) => {
                await feed.onUpdateReaction('comment', activity as GetStreamActivity<AT>, comment.id,
                  // TODO fix ts-ignore error
                  // @ts-ignore
                  reaction.data
                )
                return reaction;
              }}
              onSuccess={() => {
                setEditing(false);
              }}
            />
          ) : undefined}
          Footer={<LoveButton reaction={comment} />}

        />
      </div>

    </div>
  )

}

function ActivityComment<
  UT extends DefaultUT = DefaultUT,
  AT extends DefaultAT = DefaultAT,
  CT extends UR = UR,
  RT extends UR = UR,
  CRT extends UR = UR,
  PT extends UR = UR
>({ activity, feedGroup, userId }: ActivityProps<UT, AT, CT, RT, CRT>) {
  const feed = useFeedContext<UT, AT, CT, RT, CRT, PT>();
  const actor = userOrDefault<UT>(activity.actor);
  const user = getUserStore();
  const [editing, setEditing] = useState(false);

  if (!!activity?.reaction_counts?.['delete']) return null;

  return <Activity
    style={{
      padding: '32px 24px'
    }}
    activity={activity}
    feedGroup={feedGroup}
    userId={userId}
    Content={editing ?
      <StatusUpdateForm
        feedGroup='public'
        userId='hanger'
        Header={<></>}
        Left={<></>}
        Textarea={({ value, ...props }) => <Textarea value={value || activity.text} {...props} />}
        modifyActivityData={(newActivity) => {
          console.log('newActivity', newActivity)
          console.log('activity', activity)
          newActivity.id = activity.id;
          return newActivity
        }}
        doRequest={async (activity) => {
          // TODO need server side event to update activity
          // await feed.onUpdateActivity(activity as GetStreamActivity<AT>)
          return activity as GetStreamActivity<AT>;
        }}
        onSuccess={() => {
          setEditing(false);
        }}
        emojiI18n={{
          search: 'Type here to search...',
          categories: { recent: 'Recent Emojis' },
        }}
      /> : undefined
    }
    HeaderRight={() => (
      <div>
        <div className="btn-group dropstart">
          <button type="button" className="btn" data-bs-toggle="dropdown" aria-expanded="false" style={{
            borderRadius: '8px'
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="8" viewBox="0 0 34 8" fill="none">
              <path d="M16.8 8C19.0054 8 20.8 6.20544 20.8 4C20.8 1.79456 19.0054 0 16.8 0C14.5946 0 12.8 1.79456 12.8 4C12.8 6.20544 14.5946 8 16.8 8ZM16.8 1.6C18.1234 1.6 19.2 2.67664 19.2 4C19.2 5.32336 18.1234 6.4 16.8 6.4C15.4766 6.4 14.4 5.32336 14.4 4C14.4 2.67664 15.4766 1.6 16.8 1.6ZM29.6 0C27.3946 0 25.6 1.79456 25.6 4C25.6 6.20544 27.3946 8 29.6 8C31.8054 8 33.6 6.20544 33.6 4C33.6 1.79456 31.8054 0 29.6 0ZM29.6 6.4C28.2766 6.4 27.2 5.32336 27.2 4C27.2 2.67664 28.2766 1.6 29.6 1.6C30.9234 1.6 32 2.67664 32 4C32 5.32336 30.9234 6.4 29.6 6.4ZM4 8C6.20544 8 8 6.20544 8 4C8 1.79456 6.20544 0 4 0C1.79456 0 0 1.79456 0 4C0 6.20544 1.79456 8 4 8ZM4 1.6C5.32336 1.6 6.4 2.67664 6.4 4C6.4 5.32336 5.32336 6.4 4 6.4C2.67664 6.4 1.6 5.32336 1.6 4C1.6 2.67664 2.67664 1.6 4 1.6Z" fill="black" />
            </svg>
          </button>
          <ul
            className='dropdown-menu'
            style={{ minWidth: "110px", padding: "5px 0" }}
          >
            {`${actor.id}` === `${user.userID}` ? (
              <>
                <li>
                  <button
                    className='btn btn-light text-start'
                    style={{ width: "100%", padding: "3px 15px", borderRadius: "unset" }}
                    onClick={() => {
                      feed.onToggleReaction('delete', activity as GetStreamActivity<AT>, {} as RT, {});
                    }}
                  >
                    delete
                  </button>
                </li>
                {false && // TODO: implement edit needs server side event
                  <li>
                    <button
                      className='btn btn-light text-start'
                      style={{ width: "100%", padding: "3px 15px", borderRadius: "unset" }}
                      onClick={() => {
                        setEditing(true);
                      }}
                    >
                      edit
                    </button>
                  </li>}
              </>
            ) : (
              <li>
                <button
                  className='btn btn-light text-start'
                  style={{
                    width: "100%", padding: "3px 15px", borderRadius: "unset", color:
                      !!activity?.own_reactions?.['report']?.length ? 'grey' : undefined
                  }}
                  onClick={() => {
                    feed.onToggleReaction('report', activity as GetStreamActivity<AT>, {} as RT, {});
                  }}
                >
                  report
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    )}
    Footer={() => (
      <>
        <ActivityFooter activity={activity} feedGroup={feedGroup} userId={userId} />
        <CommentField activity={activity} image={user.profilePic} />
        {activity.reaction_counts?.['comment'] && <hr />}
        <CommentList
          activityId={activity.id}
          CommentItem={(props) => <CommentItem activity={activity} {...props} />}
        />
      </>
    )}
  />
}

function FeedInner() {
  const user = getUserStore();

  const getGetStreamUserToken = useCallback(async function getGetStreamUserToken() {
    return await fetch(`${apiHost}api/StreamAPI/GetStreamToken`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${user?.token}` // Set the Bearer token
      }
    })
      .then((res) => res.json() as Promise<{ data: { streamToken: string } }>)
      .then(data => {
        if (!data?.data?.streamToken) {
          console.error('No streamToken');
          throw new Error('No streamToken');
        }
        return data;
      })
  }, [user]);

  const query = useQuery({ queryKey: ['get-stream-user-token'], queryFn: getGetStreamUserToken, enabled: !!user?.token });

  const getStreamUserToken = query.data?.data?.streamToken;

  if (query.isLoading) {
    return (
      <div style={{ padding: '18px 24px 47px 24px ', width: '100%', height: 707, position: 'relative', background: 'white', borderRadius: 8, display: 'flex', flexDirection: 'column' }}>
        <div style={{ paddingTop: 18, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px' }}>
          <div style={{ width: 50, height: 50, background: '#D9D9D9', borderRadius: 50 }} />
          <div style={{
            display: 'flex', flexDirection: 'column', gap: '12px', flexGrow: 1
          }}>
            <div style={{ maxWidth: 265, height: 10, paddingLeft: 66, top: 10, background: '#D9D9D9', borderRadius: 8 }} />
            <div style={{ maxWidth: 147, height: 10, paddingLeft: 66, top: 32, background: 'rgba(217, 217, 217, 0.50)', borderRadius: 8 }} />
          </div>
        </div>
        <div style={{ paddingTop: 18, display: 'flex', flexDirection: 'column', gap: '19px' }}>
          <div style={{ maxWidth: 600, height: 20, background: '#D9D9D9', borderRadius: 8 }} />
          <div style={{ maxWidth: 500, height: 20, background: 'rgba(217, 217, 217, 0.50)', borderRadius: 8 }} />
          <div style={{ maxWidth: 400, height: 20, background: '#D9D9D9', borderRadius: 8 }} />
        </div>
        <div style={{ paddingTop: '53px', }}>
          <div style={{ width: '100%', height: 409, background: 'rgba(217, 217, 217, 0.50)', borderRadius: 16 }} />
        </div>
      </div>)
  }

  if (!user?.token || !getStreamUserToken) {
    return (<div>Not Logged In</div>);
  }

  return (
    <div style={{ margin: '0 auto', padding: 0 }}>
      <StreamApp
        apiKey={apiKey}
        appId={appId}
        token={getStreamUserToken}
        sharedFeeds={[
          // { feedGroup: 'notification', notify: true, options: { mark_seen: true } }
        ]}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '21px'
        }}>
          <StatusUpdateForm
            feedGroup='public'
            userId='hanger'
            Header={<p className='raf-header'>What's on your mind?</p>}
            emojiI18n={{
              search: 'Type here to search...',
              categories: { recent: 'Recent Emojis' },
            }}
          />
          <FlatFeed
            notify
            feedGroup="public"
            userId='hanger'
            options={{ limit: 6, withOwnChildren: true, withRecentReactions: true }}
            Paginator={InfiniteScrollPaginator}
            // @ts-ignore
            Activity={ActivityComment}
          />
        </div>
      </StreamApp>
    </div>
  );
}

// Create a client
const queryClient = new QueryClient()

export default function Feed() {
  return (
    <QueryClientProvider client={queryClient}>
      <FeedInner />
    </QueryClientProvider>
  );
};
