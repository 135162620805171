import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../store/hook';
import { AddMyPinAction, GetProfileCategoryMap } from '../store/reducer/pin/action';
import { AfterLoginExpertiseAction } from '../store/reducer/user/action';
import TopHeader from '../components/Header/TopHeader';
import Footer from '../components/Footer/Footer';
import { useSelector } from 'react-redux';
import FullPageLoader from '../_common/helper/FullPageLoader';
import ProfileImage from '../components/ProfileImage/ProfileImage';
import LeftNavMenu from '../components/LeftMenu/LeftNavMenu';
//import { Editor } from '@tinymce/tinymce-react';
import { TINYMCEAPIKEY } from '../config';
import Editor from 'react-simple-wysiwyg';

const AddMyPin = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const items = useSelector((state) => state.items.items);
    const navigate = useNavigate();


    const [selectAllMap, setSelectAllMap] = useState({});
    const [groupedSubcategories, setGroupedSubcategories] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectAlCatData, setSelectAlCatData] = useState({});
    const [parentCat, setParentCat] = useState([]);


    const { register, handleSubmit, control, setError, formState: { errors } } = useForm();
    const [file, setFile] = useState(null);
    const _user = JSON.parse(localStorage.getItem('user'));
    const userId = _user?.userId;
    const userProfileId = localStorage.getItem('_rfprofile'); //_user?.userProfileId;
    const dispatch = useAppDispatch();
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            //console.log(editorRef.current.getContent());
        }
    };

    useEffect(() => {
        dispatch(GetProfileCategoryMap(userProfileId));

    }, []);

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setSelectedFile(file); // Store the selected file object
        } else {
            alert('Please select an image file.');
        }
    };

    const preventDefault = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        dispatch(AfterLoginExpertiseAction())
            .then((fulfilledAction) => {

                const groupedData = groupSubcategoriesByCategory(fulfilledAction.payload);

                const getAllCat = fulfilledAction.payload;
                //console.log('getAllCat', getAllCat, groupedData)
                setSelectAlCatData(getAllCat);

                setGroupedSubcategories(groupedData);
                // Initialize selectAllMap with false for each category
                const initialSelectAllMap = {};
                Object.keys(groupedData).forEach((categoryName) => {
                    initialSelectAllMap[categoryName] = false;
                });
                setSelectAllMap(initialSelectAllMap);
                setLoading(false);
            })
            .catch((error) => {
                // Handle fetch error here (error)
                console.error('Error fetching expertise data:', error);
                setLoading(false);
            });
    }, [dispatch]);

    // Function to group subcategories by category name
    const groupSubcategoriesByCategory = (data) => {
        const groupedData = {};
        const _parentCat = [];
        if (data && Array.isArray(data.data)) {
            data.data.forEach((category) => {

                const categoryName = category.categoryName;
                const subcategories = category.subCategories;
                groupedData[categoryName] = subcategories;
                _parentCat.push(category.categoryId);
            });
            setParentCat(_parentCat);
        } else {
            console.error('Data is not in the expected structure:', data);
        }
        return groupedData;
    };


    // Initialize empty arrays to store categoryId and subCategoryId values
    const categoryIds = [];
    const subCategoryIds = [];

    // Iterate through the data
    items?.forEach(item => {
        // Extract categoryId
        categoryIds.push(item.categoryId);

        // Extract subCategoryIds
        item.subCategories.forEach(subCategory => {
            subCategoryIds.push(subCategory.subCategoryId);
        });
    });

    const isEmpty = Object.keys(selectAlCatData).length === 0;
    let filteredData = [];
    if (!isEmpty) {
        const categoryIdToFilter = categoryIds;
        // When user set their profile
        //filteredData = selectAlCatData?.data.filter(category => categoryIdToFilter.includes(category.categoryId));
        filteredData = selectAlCatData?.data;

    }

    const onSubmit = async (data) => {
        //const PinCategoriesArray = selectedCategories.join(',');
        setLoading(true); // Set loading to true when submitting
        try {
            const formData = new FormData();
            const checketed = new Set(data.PinCategories.map(String));
            const allParent = new Set(parentCat.map(String));
            // Compare the sets for equality
            const arraysAreEqual = new Set([...checketed].filter(x => allParent.has(x))).size === checketed.size;
            if (arraysAreEqual) {
                setError('PinCategories', {
                    type: 'manual',
                    message: 'Please select at least one area of expertise.'
                });
                toast.error('Please select at least one area of expertise.');
                setLoading(false);
                return false;
            }
            const pins = data.PinCategories.join(',');

            formData.append('UserId', userId);
            formData.append('CreatedBy', userId);
            formData.append('Image', selectedFile); // Use the selected file object
            formData.append('PinTitle', data.PinTitle);
            formData.append('CurrentState', data.CurrentState);
            formData.append('ProblemStatement', data.ProblemStatement);
            formData.append('DesiredOutcome', data.DesiredOutcome);
            formData.append('Solutions', data.Solutions);
            formData.append('PinCategories', pins);
            formData.append('HideMyName', data.HideMyName);  
            formData.append('Source', data.Source);
                      
            
            dispatch(AddMyPinAction(formData))
                .unwrap()
                .then((fulfilledAction) => {
                    if (fulfilledAction.success === false) {
                        setLoading(false);
                        toast.error(fulfilledAction.message);
                    }
                    if (fulfilledAction.success === true) {
                        setLoading(false);
                        toast.success(fulfilledAction.message);
                        navigate('/my-pin');
                    }
                })
                .catch((rejectedAction) => {
                    setLoading(false);
                    toast.error('Error occurred while uploading.');
                });
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
            toast.error('Error occurred while uploading.');
        }
    };

    return (
        <>
            {loading && <FullPageLoader />}
            <TopHeader />
            <div className="masonry-container page_minheight">
                <div className="container">
                    <div className="profile_page">
                        <div className="profile_laft">

                            <ProfileImage />
                            <div className="prof_menue">
                                <LeftNavMenu />
                            </div>
                        </div>
                        <div className="profile_right">
                            <div className="row">
                                <div className="col-md-10">
                                    <form className="dropzone needsclick" id="demo-upload" onSubmit={handleSubmit(onSubmit)}>
                                        <div>

                                            <div className="profile_right">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h3>Add A Test Flight</h3>
                                                </div>


                                                <div className='mb-3'>
                                                    <div id="dropzone">
                                                        <div
                                                            className="image-dropzone w-100"
                                                            onDragOver={preventDefault}
                                                            onClick={() => document.getElementById('image-input').click()}
                                                        >
                                                            <div className="dz-message needsclick">
                                                                <span className="material-symbols-outlined">
                                                                    cloud_upload
                                                                </span>

                                                                <div className="note needsclick">Click to upload image (JPEG or PNG) to be used for test flight</div>
                                                            </div>
                                                            {selectedFile ? (
                                                                <img src={URL.createObjectURL(selectedFile)} alt="Selected" />
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </div>
                                                        <input
                                                            id="image-input"
                                                            type="file"
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={handleFileInputChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label">Test Flight Title<em className='rf-required'>*</em></label>
                                                            <input type="text" {...register("PinTitle", { required: true })} className="form-control" id="exampleFormControlInput1" maxLength={240} placeholder="" />
                                                        </div>
                                                        {errors.PinTitle && <span className="rf-field-validation">Test flight title is required</span>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput10" className="form-label">Source</label>
                                                            <input type="text" {...register("Source")} className="form-control" id="exampleFormControlInput10" maxLength={1900} placeholder="" />
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label">Current State <em className='rf-required'>*</em></label>
                                                            <textarea className="form-control" {...register("CurrentState", { required: true })} maxLength={1900} ></textarea>
                                                        </div>
                                                        {errors.CurrentState && <span className="rf-field-validation">Current state is required</span>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label">Problem Statement</label>
                                                            <textarea className="form-control" {...register("ProblemStatement")} maxLength={1900} ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label">Desired Outcome</label>
                                                            <textarea className="form-control" {...register("DesiredOutcome")} maxLength={1900} ></textarea>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label">Solutions</label>
                                                            {/* <textarea className="form-control" {...register("Solutions")}></textarea> */}
                                                            {/* <Controller
                                                                name="Solutions"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Editor
                                                                        apiKey={TINYMCEAPIKEY}
                                                                        onInit={(evt, editor) => (editorRef.current = editor)}
                                                                        value={field.value}
                                                                        init={{
                                                                            height: 500,                                                                           
                                                                            menubar: false,
                                                                            plugins: [
                                                                                "advlist",
                                                                                "autolink",
                                                                                "lists",
                                                                                "link",
                                                                                "image",
                                                                                "charmap",
                                                                                "preview",
                                                                                "anchor",
                                                                                //"searchreplace",
                                                                                //"visualblocks",
                                                                                "code",
                                                                                "fullscreen",
                                                                                "insertdatetime",
                                                                                //"media",
                                                                                //"table",
                                                                                "code",
                                                                                "help",
                                                                                "wordcount",
                                                                            ],
                                                                            toolbar:
                                                                                "undo redo | blocks | " +
                                                                                "bold italic forecolor | alignleft aligncenter " +
                                                                                "alignright alignjustify | bullist numlist outdent indent | " +
                                                                                "removeformat | help",
                                                                            content_style:
                                                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                                        }}
                                                                        onEditorChange={(content, editor) => {
                                                                            if (content.length <= 1900) {
                                                                              // Update the form field value as the user types
                                                                              field.onChange(content);
                                                                            } else {
                                                                              // Optionally, you can display an error message or prevent further input here
                                                                            }
                                                                          }}
                                                                        // onEditorChange={(content, editor) => {                                                                            
                                                                        //     field.onChange(content);
                                                                        // }}
                                                                    />
                                                                )}

                                                            /> */}

                                                                <Controller
                                                                    defaultValue=" "
                                                                    control={control}
                                                                    name="Solutions"
                                                                    rules={{ required: true }}
                                                                    render={({ field: { onChange, value, ref } }) => (
                                                                        <Editor 
                                                                        style={{ minHeight: '300px' }}
                                                                        value={value}  
                                                                        //onChange={onChange}                                                                   
                                                                        onChange={(content) => {
                                                                            onChange(content);
                                                                            //handleOnChange(content, onChange); 
                                                                        }} 
                                                                        />
                                                                    )}
                                                                />
                                                        </div>
                                                    </div>

                                                </div>

                                                

                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <h3>areas of expertise</h3>
                                                        <div className="stepcategory_list">

                                                            {/* 
                                                        {filteredData?.map((category) => {
                // Check if all subcategories in this category are selected
                const areAllSubcategoriesSelected = category.subCategories.every(
                    (subCategory) => subCategoryIdsa.includes(subCategory.subCategoryId)
                );

                return (
                    <div className="category_list" key={category.categoryId}>
                        <div className="form-check">
                            <input
                                className="form-check-input parent-category-checkbox"
                                type="checkbox"
                                value={category.categoryId}
                                id={`flexCheck${category.categoryId}`}
                                {...register("PinCategories", { required: true })}
                                onChange={(e) => handleParentCheckboxChange(e, category, areAllSubcategoriesSelected)}
                            />
                            <label htmlFor={`flexCheck${category.categoryId}`}>{category.categoryName}-{category.categoryId}</label>
                        </div>

                        {category.subCategories.map((subCategory) => (
                            <div className="form-check" key={subCategory.subCategoryId}>
                                <input
                                    className="form-check-input sub-category-checkbox"
                                    type="checkbox"
                                    value={subCategory.subCategoryId}
                                    id={`flexCheck${subCategory.subCategoryId}`}
                                    {...register("PinCategories", { required: true })}
                                    {...(subCategoryIdsa.includes(subCategory.subCategoryId) ? { defaultChecked: true } : {})}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={`flexCheck${subCategory.subCategoryId}`}>{subCategory.subCategoryName}</label>
                            </div>
                        ))}
                    </div>
                );
            })} */}



                                                            {filteredData?.map((category) => (
                                                                <div className="category_list" key={category.categoryId}>
                                                                    <div className='d-none'>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={category.categoryId}
                                                                            id={`flexCheck${category.categoryId}`}
                                                                            {...register("PinCategories", { required: true })}
                                                                            defaultChecked

                                                                        />
                                                                    </div>
                                                                    <h5>{category.categoryName}</h5>

                                                                    {category.subCategories.map((subCategory) => (
                                                                        <div className="form-check" key={subCategory.subCategoryId}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                value={subCategory.subCategoryId}
                                                                                id={`flexCheck${subCategory.subCategoryId}`}
                                                                                {...register("PinCategories", { required: true })}
                                                                                {...(subCategoryIds.includes(subCategory.subCategoryId) ? { defaultChecked: true } : {})}

                                                                            />
                                                                            <label className="form-check-label" htmlFor={`flexCheck${subCategory.subCategoryId}`}>
                                                                                {subCategory.subCategoryName}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}


                                                            {errors.PinCategories && <span className="rf-field-validation">Please select at least one area of expertise.</span>}

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div >
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <h3 className="border_bottom">Hide My Name</h3>
                                                            </div>
                                                            <div className="form-check form-switch mb-3">
                                                                <input className="form-check-input" type="checkbox"
                                                                    {...register("HideMyName")}                                                                     
                                                                    role="switch" id="flexSwitchCheckDefault" />
                                                                <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">ON / OFF</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center">

                                                    <button className="btn btn-danger text-uppercase ms-2" type='submit'>Submit for Review</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default AddMyPin