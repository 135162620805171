import Feed from "../components/Feed";
import Footer from "../components/Footer/Footer";
import OnlandMessage from "../modals/onland.modal";
import ProfileBadge from "../components/profile-badge";
import TopHeader from "../components/Header/TopHeader";
import { useMediaQuery } from "../hooks/use-media-query";

const TheClub = () => {
  const isSmall = useMediaQuery("(min-width: 768px)");
  return (
    <>
      <TopHeader />
      <div className='masonry-container page_minheight'>
        <div className='container'>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "24px",
            }}
          >
            {isSmall && (
              <div
                style={{
                  position: "sticky",
                  top: "88px",
                  alignSelf: "flex-start",
                  width: "245px",
                  padding: "0px",
                }}
              >
                <ProfileBadge />
              </div>
            )}
            <div style={{ maxWidth: "847px", marginRight: "auto" }}>
              <Feed />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <OnlandMessage />
    </>
  );
};

export default TheClub;
