import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Cookies from 'js-cookie';
import { Form } from 'react-bootstrap';
import { URLS } from '../routes';
import { Link } from 'react-router-dom';

const OnloadMessage = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(()=>{
        if(Cookies.get('PWDRSET') == undefined){
            setShow(true)
        }
    },[])
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>We are migrating from Beta to Full Production! If you have an existing account, we kindly ask that you update your password information to keep your account with the InnovationWorks Hangar active. Please do this by clicking the <b style={{color:'#B32F84'}}>Forgot Password</b> button upon sign-in.</p>
                
                <div className='mt-3'>
                    <Form.Check // prettier-ignore
                        className='small-check'
                        type={`checkbox`}
                        id={`pwd-rset`}
                        label={`I have already reset my password, please don't show this message again.`}
                        onChange={(e)=>{
                            Cookies.set('PWDRSET',e.target.checked)
                        }}
                    />
                   
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OnloadMessage





