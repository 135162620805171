import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../store/hook';
import { SendOTP, ValidateOTP, userClerkLoginAction, userLoginAction } from '../../store/reducer/user/action';
import { toast } from 'react-toastify';
import Loading from 'react-loading'; // Import the Loading component
import Swal from 'sweetalert2';
import { RotatingLines } from 'react-loader-spinner';
import FullPageLoader from '../../_common/helper/FullPageLoader';
import { LOGIN_VERIFICATION, LOGO_IMAGE } from '../../config';
import { SignedOut, useUser, useAuth, SignIn, SignInButton, SignedIn, SignOutButton, } from "@clerk/clerk-react"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OnloadMessage from '../../modals/onload.modal';
import { URLS } from '../../routes';



const ClerkLogin = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false); // Add loading state
    const location = useLocation();


    const [clarkLogin, setClarkLogin] = useState(false);
    const ClerkUser = useUser();
    const ClerkAuth = useAuth();

    const loginWithClerkUser = () => {
        console.log('Login with ClerkUser =>', ClerkUser);

        setLoading(true); // Set loading to true when submitting

        let params = {
            "firstName": ClerkUser.user.firstName?ClerkUser.user.firstName:ClerkUser.user.primaryEmailAddress.emailAddress.split('@')[0],
            "lastName": ClerkUser.user.lastName?ClerkUser.user.lastName:(ClerkUser.user.primaryEmailAddress.emailAddress.split('@')[0]),
            "phoneNumber": ClerkUser.user.primaryPhoneNumber,
            "email": ClerkUser.user.primaryEmailAddress.emailAddress,
            "isPublicEnvironment": true,
            "password": ''
        };    

        dispatch(userClerkLoginAction(params)).unwrap().then((fulfilledAction) => {
            setLoading(false);
            console.log('Resp=>',fulfilledAction);
            if(fulfilledAction.token){
                if (LOGIN_VERIFICATION) {
                    applyUserData(fulfilledAction);
                } else {
                    applyUserDataProd(fulfilledAction)
                }
                
            } else {
                setLoading(false); // Set loading to false when login fails
                toast.error(fulfilledAction.message);
                ClerkAuth.signOut()
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.message);
            ClerkAuth.signOut()
        });
    }


    const applyUserData = (fulfilledAction)=>{
        const userProfileId = fulfilledAction.userProfileId;
        const profileStatus = fulfilledAction.profileCompleteness
        const isApproved = fulfilledAction.isApproved;
        const twofa = fulfilledAction.twoFA;
        const email = fulfilledAction.email;

        // Url Redirect Code
        let queryParams = new URLSearchParams(location.search);
        let redirectTo = queryParams.get('redirectto');
        if (redirectTo) {
            let url = redirectTo.replace(/"/g, '');
            const parts = url.split('/');
            const dynamicValue = parts[parts.length - 1];
            let redirectUrl = `/view-pin/${dynamicValue}`
            navigate(redirectUrl);
            setLoading(false);
        } else {
            if (isApproved === 1 && profileStatus <= 2 && twofa === false) {
                setLoading(false);
                switch (profileStatus) {
                    case 0:
                        navigate('/profile-customization');
                        break;
                    case 1:
                        navigate('/expertise');
                        break;
                    case 2:
                        navigate(URLS.CLUB);
                        break;
                    default:
                        navigate(URLS.CLUB);
                        break;
                }
            } else {
                if (isApproved === 0) {
                    switch (profileStatus) {
                        case 0:
                            navigate('/profile-customization');
                            break;
                        case 1:
                            navigate('/expertise');
                            break;
                        default:
                            navigate('/access-denied');
                            break;
                    }
                } else if (isApproved === 2) {
                    navigate('/permission-denied');
                } else if (twofa === true) {
                    let otpParam = {
                        email: email,
                    };
                    dispatch(SendOTP(otpParam)).unwrap().then((fulfilledAction) => {
                        //console.log('fulfilledAction:', fulfilledAction);
                        if (fulfilledAction.success === true) {
                            setLoading(false);
                            Swal.fire({
                                title: "Check your email! We've sent you a verification code to enter below.",
                                input: 'text',
                                inputAttributes: {
                                    autocapitalize: 'off',
                                },
                                showCancelButton: true,
                                confirmButtonText: 'Submit',
                                showLoaderOnConfirm: true,
                                customClass: {
                                    title: 'custom-title-class-otp', // Add a custom class to the title element
                                    confirmButton: 'btn btn-danger',
                                    cancelButton: 'btn btn-secondary',
                                    input: 'otp-input-class',
                                },
                                preConfirm: (otp) => {
                                    if (!otp) {
                                        // Show validation message if OTP is empty
                                        Swal.showValidationMessage('OTP cannot be empty');
                                        return false; // Prevent dialog from closing
                                    }

                                    let otpSendParam = {
                                        email: email,
                                        otp: otp,
                                    };
                                    return dispatch(ValidateOTP(otpSendParam))
                                        .unwrap()
                                        .then((validationAction) => {
                                            //console.log('validationAction:', validationAction);
                                            if (validationAction.success === true) {
                                                // OTP validation success
                                                return true;
                                            } else {
                                                // OTP validation failed, show an error message

                                                Swal.showValidationMessage(`${validationAction.message}`);
                                                return false;

                                            }
                                        });
                                },
                                allowOutsideClick: () => !Swal.isLoading(),
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    localStorage.setItem('_twofa', 'true');
                                    // OTP was successfully validated, navigate to the next step
                                    navigate('/my-profile');
                                }
                            });
                        } else {
                            // Handle case where sending OTP failed
                            Swal.fire('Error', 'Failed to send OTP. Please try again.', 'error');
                        }
                    }).catch((error) => {
                        // Handle errors from dispatching SendOTP
                        Swal.fire('Error', `Request failed: ${error.message}`, 'error');
                    });
                }


            }
        }
    }

    const applyUserDataProd = (fulfilledAction)=>{
        const userProfileId = fulfilledAction.userProfileId;
                    const profileStatus = fulfilledAction.profileCompleteness
                    const isApproved = fulfilledAction.isApproved;
                    const twofa = fulfilledAction.twoFA;
                    const email = fulfilledAction.email;
                    localStorage.setItem('_email_verified', fulfilledAction.isEmailVerified);
                    localStorage.setItem('_approved_status', fulfilledAction.isApproved);

                    // Url Redirect Code
                    let queryParams = new URLSearchParams(location.search);
                    let redirectTo = queryParams.get('redirectto');
                    if (redirectTo) {
                        let url = redirectTo.replace(/"/g, '');
                        const parts = url.split('/');
                        const dynamicValue = parts[parts.length - 1];
                        let redirectUrl = `/view-pin/${dynamicValue}`
                        navigate(redirectUrl);
                        setLoading(false);
                    } else {
                        // End Url Redirect Code


                        if (isApproved === 1 && profileStatus <= 2 && twofa === false) {
                            setLoading(false);
                            //console.log('All Access');
                            switch (profileStatus) {
                                case 0:
                                    //navigate('/profile-customization');
                                    navigate(URLS.CLUB);
                                    break;
                                case 1:
                                    //navigate('/expertise');
                                    navigate(URLS.CLUB);
                                    break;
                                case 2:
                                    //navigate('/my-profile');
                                    navigate(URLS.CLUB);
                                    break;
                                default:
                                    // navigate('/my-profile');
                                    navigate(URLS.CLUB);
                                    break;
                            }
                        } else {
                            //console.log('Else All Access');
                            if (isApproved === 0) {
                                //console.log('isApproved === false');
                                switch (profileStatus) {
                                    case 0:
                                        // navigate('/profile-customization');
                                        navigate(URLS.CLUB);
                                        break;
                                    case 1:
                                        //navigate('/expertise');
                                        navigate(URLS.CLUB);
                                        break;
                                    default:
                                        navigate('/access-denied');
                                        break;
                                }
                                //navigate('/access-denied');
                            } else if (isApproved === 2) {
                                navigate('/permission-denied');
                                // navigate(URLS.CLUB);
                            } else if (twofa === true) {
                                //setLoading(true);
                                //console.log('twofa === true');
                                let otpParam = {
                                    email: email,
                                };
                                dispatch(SendOTP(otpParam))
                                    .unwrap()
                                    .then((fulfilledAction) => {
                                        //console.log('fulfilledAction:', fulfilledAction);
                                        if (fulfilledAction.success === true) {
                                            setLoading(false);
                                            Swal.fire({
                                                title: "Check your email! We've sent you a verification code to enter below.",
                                                input: 'text',
                                                inputAttributes: {
                                                    autocapitalize: 'off',
                                                },
                                                showCancelButton: true,
                                                confirmButtonText: 'Submit',
                                                showLoaderOnConfirm: true,
                                                customClass: {
                                                    title: 'custom-title-class-otp', // Add a custom class to the title element
                                                    confirmButton: 'btn btn-danger',
                                                    cancelButton: 'btn btn-secondary',
                                                    input: 'otp-input-class',
                                                },
                                                preConfirm: (otp) => {
                                                    if (!otp) {
                                                        // Show validation message if OTP is empty
                                                        Swal.showValidationMessage('OTP cannot be empty');
                                                        return false; // Prevent dialog from closing
                                                    }

                                                    let otpSendParam = {
                                                        email: email,
                                                        otp: otp,
                                                    };
                                                    return dispatch(ValidateOTP(otpSendParam))
                                                        .unwrap()
                                                        .then((validationAction) => {
                                                            //console.log('validationAction:', validationAction);
                                                            if (validationAction.success === true) {
                                                                // OTP validation success
                                                                return true;
                                                            } else {
                                                                // OTP validation failed, show an error message

                                                                Swal.showValidationMessage(`${validationAction.message}`);
                                                                return false;

                                                            }
                                                        });
                                                },
                                                allowOutsideClick: () => !Swal.isLoading(),
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    localStorage.setItem('_twofa', 'true');
                                                    // OTP was successfully validated, navigate to the next step
                                                    navigate('/my-profile');
                                                }
                                            });
                                        } else {
                                            // Handle case where sending OTP failed
                                            Swal.fire('Error', 'Failed to send OTP. Please try again.', 'error');
                                        }
                                    })
                                    .catch((error) => {
                                        // Handle errors from dispatching SendOTP
                                        Swal.fire('Error', `Request failed: ${error.message}`, 'error');
                                    });
                            }


                        }
                    }
                    //console.log('login', fulfilledAction.userProfileId)
                    // setLoading(false); // Set loading to false when login is successful
    }

    useEffect(()=>{
        if(ClerkUser.isSignedIn && !clarkLogin){
            setClarkLogin(true)
            loginWithClerkUser();
        }
    },[ClerkUser])

    console.log(location)

    return (
        <React.Fragment>
            {loading && <FullPageLoader />}
            <div className="login_page">
            
                <div className="justify-content-center mb-5 mt-5">
                    {ClerkUser?.isLoaded ?
                        <SignedOut >
                            <SignIn redirectUrl={window.location.href} signUpUrl={window.location.origin + URLS.SIGNUP} />
                        </SignedOut>
                        :
                        <p>Loading...</p>
                    }
                    <SignedIn>
                        <p>Checking Authentication...</p>
                    </SignedIn>
                </div>
            </div>

            <OnloadMessage />
        </React.Fragment>
    )
}

export default ClerkLogin